import React from 'react'

import Layout from '../component/layout'
import JourneyOmnidya from '../component/journeyOmnidya'
import Link from '../component/link'
import PageTitle from '../component/typography/pageTitle'
import Title from '../component/typography/title'

const Titles = (props) => {
  return(
    <Title className="pb-3" {...props} />
  )
}

const TermsConditions = () => {
  return(
    <Layout title="Terms & Conditions">
      <div className="container-xxl">
        <div className="d-flex justify-content-center py-5">
          <Link to="/termsCondition/" className="btn btn-blue btn-pill">
            Terms & Conditions
          </Link>
          <Link to="/privacyPolicy/" className="btn btn-pill">
            Privacy Policy
          </Link>
        </div>
        
        <div className="row justify-content-center pb-5">
          <div className="col col-lg-6 text-center">
            <PageTitle title="Terms & Conditions" className="pb-3" />
            <p>
              These terms and conditions ("Terms", "Agreement") are an agreement between Remote Technology, Inc. ("Remote Technology, Inc.", "us", "we" or "our") and you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of your use of the remote.com website and any of its products or services (collectively, "Website" or "Services").
            </p>
          </div>
        </div>
        
        <div className="row justify-content-center">
          <div className="col col-lg-6">
            <div className="mb-5">
              <Titles title="Accounts and Membership" />
              <p>
                You must be at least 18 years of age to use this Website. By using this Website and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and use our Services.  
              </p>
              <p>
                Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.
              </p>
            </div>
            <div className="mb-5">
              <Titles title="User Content" />
              <p>
                We do not own any data, information or material ("Content") that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may, but have no obligation to, monitor and review Content on the Website submitted or created using our Services by you. Unless specifically permitted by you, your use of the Website does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose. But you grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. 
              </p>
            </div>
            <div className="mb-5">
              <Titles title="Billing and Payments" />
              <p>
                We do not own any data, information or material ("Content") that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may, but have no obligation to, monitor and review Content on the Website submitted or created using our Services by you. Unless specifically permitted by you, your use of the Website does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose. But you grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. 
              </p>
            </div>
            <div className="mb-5">
              <Titles title="Acceptance of these Terms" />
              <p>
                You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Website and its Services. 
              </p>
            </div>
            <div className="mb-5">
              <Titles title="Contacting us" />
              <p>
                If you have any questions about this Agreement, please contact us at support@remote.com.
              </p>
              <p>
                This document was last updated on July 5, 2019 
              </p>
            </div>
          </div>
        </div>
        <JourneyOmnidya gutter />
      </div>
    </Layout>
  )
}

export default TermsConditions